.order-page-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
}

.order-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    color: #555;
}

.form-group input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
}

.form-group input:focus {
    border-color: #007BFF; /* Change border color on focus */
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.order-summary-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.order-summary-table th,
.order-summary-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.order-summary-table th {
    background-color: #007BFF;
    color: white;
}

.order-summary-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.confirm-order-btn {
    padding: 10px 20px;
    background-color: #28a745; /* Bootstrap success color */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
    align-self: center; /* Center the button */
}

.confirm-order-btn:hover {
    background-color: #218838; /* Darker shade on hover */
}
.image-preview {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.image-preview img {
    width: 100px; /* Adjust size as needed */
    height: auto;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.image-preview button {
    padding: 5px 10px;
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.image-preview button:hover {
    background-color: #c82333; /* Darker shade on hover */
}

@media (max-width: 600px) {
    .order-page-container {
        padding: 15px;
    }

    .confirm-order-btn {
        width: 100%; /* Make button full width on smaller screens */
    }
}
.toast-message {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    opacity: 0.9;
    font-size: 14px;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
  }
  
  .toast-message.show {
    opacity: 1;
  }
  
  .toast-message.hide {
    opacity: 0;
  }
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures modal is on top */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .modal-content button {
    margin: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  