.product-gallery {
  text-align: center;
  padding: 130px 20px;
  background-color: #063931;
}
 .product-gallery h1{
  color: #bf9b42;
 }
.product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.product-card {
  border: 2px solid #fff;
  border-radius: 8px;
  margin: 15px;
  padding: 10px;
  width: 201px;
  height: 303px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.product-card h3{
  font-weight: 400;
}
.size{
  color: #bf9b42;
  font-weight: 400;
}
.product-image {
  width: 173px;
  height: 154px;
  border-radius: 5px;
}

.photo {
  margin-left: 20% ;
}

.add-to-cart-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 45px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.add-to-cart-btn:hover {
  background-color: #45a049;
}

.add-to-cart-btn svg {
  margin-right: 5px;
}
.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}
/* Mobile view */
@media (max-width: 768px) {
  .product-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    padding-bottom: 15px;
  }

  .product-card {
    flex: 0 0 auto;
    margin: 10px;
  }
}


@media (max-width:480px){
  .product-gallery{
    padding:20px 20px;;
  }
}

/* Desktop view hover effect */
