.header {
  display: flex;
  align-items: center;
}

.home-icon {
  cursor: pointer;
  font-size: 24px;
  margin-right: 15px;
  color: #007bff; /* Primary color */
}

.home-icon:hover {
  color: #0056b3; /* Darker shade on hover */
}

.my-order-list-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .order-list {
    list-style-type: none;
    padding: 0;
  }
  
  .order-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s;
  }
  
  .order-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-date {
    font-size: 14px;
    color: #666;
  }
  
  .order-status {
    display: flex;
    align-items: center;
  }
  
  .status-icon {
    margin-right: 5px;
    font-size: 18px;
  }
  
  .confirmed {
    color: green;
  }
  
  .pending {
    color: orange;
  }
  .pending-icon {
    color: orange;
  }
  
  .confirmed-icon {
    color: green;
  }
  .place-order-button {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .place-order-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  .my-order-list-container {
    padding: 20px;
  }
  
  .order-list {
    list-style-type: none;
    padding: 0;
  }
  
  .order-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
  }
  
  .order-info {
    flex-grow: 1;
  }
  
  .order-icons {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-right: 10px;
  }
  
  .order-details {
    border: 2px solid #007bff;
    padding: 15px;
    margin-top: 20px;
  }
  
  .order-details h2 {
    margin-top: 0;
  }
  
.view-order-button {
  margin-left: 10px;
  padding: 10px 15px;
  background-color: #007bff; /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.view-order-button:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.empty-orders {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 600px; /* Adjust height as needed */
  text-align: center;
}

.empty-image {
  width: 150px; /* Adjust width as needed */
  height: auto;
  margin-bottom: 20px;
}

.empty-orders p {
  font-size: 18px;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .my-order-list-container {
    padding: 10px;
  }

  .order-item {
    padding: 10px;
  }

  .view-order-button {
    padding: 8px 12px;
  }

  h1 {
    font-size: 20px;
  }
}