.our-services-page {
  padding: 120px 20px;
  text-align: center;
  background-color: #3d2c03;
}

.our-services-page h1 {
  margin-bottom: 40px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

.service-section {
  margin-bottom: 60px;
  /* Space between services */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease-in-out;
  /* Animation */
}

.service-content {
  display: flex;
  align-items: center;
  /* Align items vertically */
  justify-content: space-evenly;
  /* Center the content */
}

.service-section h2 {
  margin: 20px 0;
  font-size: 2rem;
}

.service-image {
  width: 100%;
  max-width: 400px;
  /* Increased width for larger images */
  height: auto;
  border-radius: 10px;
  margin: 0 20px;
  /* Space between image and text */
  animation: slideIn 0.5s ease-in-out;
  /* Animation */
}

.text-content {
  max-width: 600px;
  /* Limit text width for better readability */
}
.text-content h2{
  color: #fff;
}

.service-section p {
  font-size: 18px;
  line-height: 1.5;
  color: #bf9b42;
  font-weight: 300;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .service-content {
    flex-direction: column-reverse;
    align-items: center;
  }

  .service-image {
    max-width: 90%;
    /* Make image smaller on mobile */
    margin: 20px 0;
    /* Add margin for spacing */
  }

  .text-content {
    text-align: center;
    /* Center text on mobile */
  }
}
@media (max-width:480px){
 .service-section p{
  font-size: 14px;
  font-weight: 400;
 }
 .our-services-page h1{
  font-size: 30px;
  font-weight: 100;
  margin-bottom: -18px;
 }
 .text-content h2{
  font-size: 24px;
  font-weight: 300;

 }
 .our-services-page{
  padding: 20px 20px;
 }
}
  


/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}