.caricature-product-gallery {
  padding: 60px 20px;
  text-align: center;
  background-color: #063931;
}
.caricature-product-gallery h1{
  color: #bf9b42;
}
.caricature-product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.caricature-product-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 15px;
  padding: 10px;
  width: 220px;
  text-align: center;
  transition: transform 0.3s ease;
}

.caricature-product-image {
  width: 200px;
  height: 200px;
  border-radius: 5px;
}
.cpp{
  margin-left: 35px !important;
}
.add-to-cart-btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 45px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.caricature-product-card:hover{
  transform: scale(0.9);
}
.add-to-cart-btn:hover {
  background-color: #45a049;
}

.add-to-cart-btn svg {
  margin-right: 5px;
}

/* Mobile view */
@media (max-width: 768px) {
  .caricature-product-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    padding-bottom: 15px;
  }

  .caricature-product-card {
    flex: 0 0 auto;
    margin: 10px;
  }
}
@media (max-width:480px){
  .caricature-product-gallery {
    padding: 20px 20px;;
  }
}