/* Main container for the BudgetFrame */
.budget-frame-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    padding: 10px;
    height: 40vh; /* Ensure the container takes full viewport height */
  }
  
  /* Image container for controlling the image size */
  .budget-image-container {
    width: 100%;
    max-width: 98%; /* Set the max-width to 98% for landscape image */
    height: 100%; /* Allow the container to take full height */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
  }
  
  /* Style for the image */
  .budget-frame-image {
    
    width: 100%;
    height: auto; /* Maintain aspect ratio while scaling */
    object-fit: contain; /* Ensures the full height and width of the image are visible */
    border-radius: 12px; /* Optional for rounded corners */
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .budget-frame-container {
      margin: 10px;
      padding: 5px;
      height: auto; /* Allow auto height on smaller screens */
    }
  
    .budget-frame-image {
      max-width: 100%; /* Ensure image takes full width on mobile */
      height: auto;
    }
  }
  
  @media (max-width: 480px) {
    .budget-frame-container {
      margin: 5px;
      padding: 0;
      height: auto;
    }
  
    .budget-frame-image {
      max-width: 100%; /* Ensure image takes full width on smaller screens */
      height: auto;
    }
  }
  