.neon-light-product-gallery {
  padding: 20px;
  text-align: center;
}

.neon-light-product-gallery h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #063931;
}

.neon-light-product-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.neon-light-product-card {
  border: 2px solid #FF69B4;
  border-radius: 10px;
  background-color: #FFF0F5;
  padding: 20px;
  transition: transform 0.3s ease;
  box-shadow: 1px 0px 20px 10px #eb0129;
  max-width: 400px;
}

.neon-light-product-images {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.neon-light-product-image {
  width: 100%;
  max-width: 150px;
  height: 150px;
  object-fit: cover;
}

.add-to-cart-btn {
  background-color: #FF1493;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #FF69B4;
}

.neon-light-product-card h3 {
  font-size: 1.5rem;
  margin-top: 10px;
  color: #FF1493;
}

.neon {
  margin-left: 28% !important;
}

.neon-light-product-card p {
  font-size: 1.2rem;
  color: #696969;
}

.animate-container22 {
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  padding: 45px 30px;
}

.neon-light-video {
  width: 300px;
  height: auto;
  border-radius: 50%;
}

.neon-light-video::-webkit-media-controls {
  display: none; /* Hides video controls on supported browsers */
}

@media (max-width: 480px) {
  .neon-light-video {
    display: none;
  }
  .animate-container22 {
    padding: 44px 0px;
  }
  .neon-light-product-gallery h1 {
    margin-bottom: -45px;
    font-size: 24px;
  }
}
