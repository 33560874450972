/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Outfit", sans-serif;
    background-color: #fff;
    color: #25252b;
}

/* Hero Section */
.hero-section {
    text-align: center;
    padding: 50px 20px;
}

/* Heading */
.img-frames h1 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #063931;
}

/* Image Grid (4 Images Per Row) */
.image-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    max-width: 900px;
    margin: 0 auto;
}

/* Individual Grid Item */
.grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.grid-item:hover {
    transform: scale(1.05);
}

/* Image Styling */
.grid-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
}

/* Text Styling */
.grid-text {
    margin-top: 10px;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .image-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Mobile View (2 images per row + Horizontal Scroll) */
@media (max-width: 768px) {
    .image-grid {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 10px;
    }

    .grid-item {
        flex: 0 0 auto;
        scroll-snap-align: center;
        width: 50%; /* 2 items per row */
        max-width: 200px;
    }

    .grid-image {
        width: 130px;
        height: 130px;
    }
}

/* Additional Styling for Mobile Scrolling */
.image-grid::-webkit-scrollbar {
    display: none; /* Hide scrollbar in mobile */
}
