.acrylic-product-gallery {
  padding: 130px 20px;
  text-align: center;
  background-color: #fff;
}

.acrylic-product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
}
.acrylic-product-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 198px;
  height: 273px;
  transition: transform 0.2s;
}
.acrylic-product-card h3,p{
  color: #063931;
  font-weight: 400;
}
.acrylic-product-card:hover {
  transform: scale(1.05);
}

.acrylic-product-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.add-to-cart-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  font-family: 'Poppins';
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 28px;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #218838;
}
.acrylic-product-gallery h1{
  color: #063931;
}
h1 {
  margin-bottom: 20px;
  font-size: 40px;
  font-family: "Yatra One", system-ui;
  font-weight: 400;
}
p {
  font-size: 1.2rem;
  font-weight: 500;
}

/* Mobile view */
@media (max-width: 768px) {
  .acrylic-product-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    justify-content: flex-start;
    padding-bottom: 15px;
  }

  .acrylic-product-card {
    flex: 0 0 auto;
  }
}
@media (max-width:480px){
  .acrylic-product-gallery {
    padding: 20px 20px;;
  }
}