.wood-engraving-product-gallery {
  padding: 90px 20px;
  text-align: center;
}

.wood-engraving-product-gallery h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #063931; /* Brown color to match wood theme */
}

.wood-engraving-product-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  width: 100%;
  margin-top: 30px;
}

.wood-engraving-product-card {
  border: 2px solid #D2B48C; /* Light brown color */
  border-radius: 10px;
  width: 215px;
  height: 291px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.wood-engraving-product-card:hover {
  transform: scale(1.05);
}

.wood-engraving-product-image {
  width: 133px;
  height: 143px;
  object-fit: cover;
}

.add-to-cart-btn {
  background-color: #8B4513;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #A0522D;
}

.wood-engraving-product-card h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #063931;
}

.wood-engraving-product-card p {
  font-size: 1rem;
  color: #063931;
}
.wood{
  margin-left: 40px;
  margin-bottom: 10px;
}
/* Mobile view */
@media (max-width: 768px) {
  .wood-engraving-product-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    justify-content: flex-start;
    padding-bottom: 15px;
  }

  .wood-engraving-product-card {
    flex: 0 0 auto;
    margin: 0 10px; /* Add space between cards */
  }
}
@media (max-width:480px){
  .wood-engraving-product-gallery h1{
    font-size: 24px;
  }
  .wood-engraving-product-card:hover{
    transform: none;
  }
  .wood-engraving-product-gallery{
    padding: 20px 20px;
  }
}
