.profile-page-container {
  padding: 40px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  background-color: #f9f9f9;
  border: 2px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.profile-heading h2 {
  text-align: center;
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 30px;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

.profile-field {
  margin-bottom: 20px;
}

.profile-label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #555;
}

.profile-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #f7f7f7;
}

.profile-text {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  font-size: 1rem;
}

.profile-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.back-arrow {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.back-arrow:hover {
  color: #007bff;
}

.profile-btn {
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.profile-btn:hover {
  background-color: #ff6600;
}

.profile-save-btn {
  background-color: #28a745;
}

.profile-cancel-btn {
  background-color: #dc3545;
}

.profile-edit-btn {
  background-color: #007bff;
}

.profile-loading {
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}

.toastify-container {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .profile-page-container {
      padding: 20px;
      width: 90%;
      max-width: 400px;
  }

  .profile-heading h2 {
      font-size: 1.5rem;
      margin-bottom: 20px;
  }

  .profile-btn {
      padding: 10px 15px;
      font-size: 0.9rem;
  }

  .profile-input, .profile-text {
      font-size: 0.9rem;
      padding: 8px;
  }
}

@media (max-width: 480px) {
  .profile-page-container {
      padding: 15px;
      width: 95%;
      max-width: 350px;
  }

  .profile-heading h2 {
      font-size: 1.2rem;
  }

  .profile-btn {
      padding: 8px 10px;
      font-size: 0.8rem;
  }

  .profile-input, .profile-text {
      font-size: 0.8rem;
      padding: 6px;
  }

  .profile-actions {
      flex-direction: column;
      gap: 10px;
  }

  .profile-save-btn, .profile-cancel-btn {
      width: 100%;
  }
}
