.carousel {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 12px;
}

.carousel-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image {
  width: 100%; 
  max-height: 700px;
  object-fit: cover;
  border-radius: 12px;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #383838;
  font-size: 24px;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 8px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border-radius: 18%;
}

.prev:hover,
.next:hover {
  background-color: rgba(255, 255, 255, 0.8);

}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.dots {
  text-align: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
}

.dot {
  height: 12px;
  width: 14px;
  margin: 0 4px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
}

.img-frames h1 {
  font-size: 38px;
  margin-top: 18px;
  color: #063931;
  font-family: "Yatra One", system-ui;
  font-weight: 400;
}

/* Tablet Responsive Adjustments */
@media (max-width: 768px) {
  .carousel {
    max-height: 400px;
  }

  .carousel-image {
    max-height: 300px;
  }

  .prev,
  .next {
    font-size: 20px;
    padding: 6px;
  }

  .image-buttons {
    margin-top: 20px;
  }

  .rectangle {
    width: 40%;
    height: 200px;
  }

  .img-frames h1 {
    font-size: 30px;
  }
}

/* Mobile Responsive Adjustments */
@media (max-width: 480px) {
  .carousel {
    max-height: 300px;
  }

  .carousel-image {
    max-height: 200px;
  }

  .prev,
  .next {
    font-size: 18px;
    padding: 4px;
  }

  .dot {
    height: 10px;
    width: 10px;
  }

  .image-buttons {
    margin-top: 15px;
  }

  .btn-r-res {
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn-r-res1 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .rectangle {
    max-width: 120px;
    max-height: 155px;
    width: 100%;
    object-fit: cover;
  }

  .square {
    max-width: 120px;
    height: auto;
  }

  .img-frames h1 {
    font-size: 20px;
    margin-top: 10px;
  }

  .image-button {
    width: 100px;
    height: 70px;
  }

  .button-name {
    font-size: 16px !important;
    text-align: center;
    margin-top: 5px;
  }
  .button-row{
    flex-wrap: nowrap !important;
    width: 92% !important;
  }
}

/* Image Buttons Styles */
.image-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.button-row {
  display: flex;
  justify-content: center;
  gap: 30px;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.image-button {
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.rectangle {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: -2px 2px 15px 8px rgb(18 18 18 / 26%);
}

.square {
  width: 20%;
  height: 310px;
  object-fit: cover;
  border-radius: 10px;
}

.button-name {
  font-size: 20px;
  font-family: "Yatra One", system-ui;
  font-weight: 400;
  text-align: center;
  margin-top: 8px;
  color: #063931;
}
