/* Base styles for the header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 14px;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    background-color: #1a1a1a;
}

/* Logo styles */
.logo img {
    width: 70px;
    border-radius: 50%;
    height: 58px;
}

/* Search bar styles */
.search-bar {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin: 0 20px;
}

.search-bar input {
    width: 30%;
    padding: 10px;
    border: 2px solid #b3bbc1;
    border-radius: 20px 0 0 20px;
    font-family: "Outfit", sans-serif;
}

.search-button {
    padding: 11px;
    background-color: #fff;
    border: 2px solid #b3bbc1;
    border-left: none;
    border-radius: 0 20px 20px 0;
    color: #000;
}


/* Icon Button Styles */
.icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: #000;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.icon-button:hover {
    background-color: #555;
}

/* Login Button Styles */
.login-button {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    /* Rectangular shape */
    background-color: #bf9b42;
    /* Button color */
    border: none;
    font-family: "Outfit", sans-serif;
    border-radius: 5px;
    /* Rounded corners */
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #033631;
    /* Darker shade on hover */
}

.login-button svg {
    margin-right: 5px;
    /* Space between icon and text */
}

/* Container for desktop-specific icons */
.desktop-icons {
    display: flex;
    padding: 20px;
    gap: 20px;
}

/* Profile Modal Styles */
.profile-modal {
    position: absolute;
    right: 20px;
    top: 100px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: all 0.3s ease;
    min-width: 150px;
}

/* Hide bullet points in the modal list */
.profile-modal ul {
    list-style-type: none;
    /* Hide bullet points */
    padding-left: 0;
    /* Remove default padding */
    margin: 0;
    /* Remove margin for proper alignment */
}

.profile-modal ul li {
    margin-left: 10px;
    padding: 10px 0;
    font-size: 16px;
    cursor: pointer;
}

.profile-modal ul li:hover {
    background-color: #f0f0f0;
}

/* Sidebar Styles */
.sidebar {
    position: fixed;
    top: 0px;
    right: 0;
    width: 100%;
    height: 50%;
    background-color: #0c1e2b;
    color: white;
    padding: 20px;
    z-index: 1000;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.sidebar ul {
    list-style: none;
    padding-left: 0;
    margin-top: 38px;
}

.sidebar li {
    padding: 22px 0;
    font-size: 18px;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

/* Close button (X) in the sidebar */
.close-sidebar {
    position: absolute;
    top: 25px;
    right: 20px;
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
}

/* Hamburger menu icon */
.hamburger-icon {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    display: none;
    color: white;
    /* Initially hidden for desktop */
}

/* Mobile styles */
@media (max-width: 768px) {
    .desktop-icons {
        display: none;
        /* Hide Cart and Phone icons on mobile */
    }

    .hamburger-icon {
        display: block;
        /* Show hamburger in mobile view */
    }

    .icon-button {
        display: none;
        /* Hide other icons in mobile */
    }
    .search-bar input {
        width: 100%;
    }
}

.search-bar {
    flex-grow: 1;
    display: flex;
    align-items: center;
    margin: 0 20px;
    position: relative;
    /* Ensure the search results stay within this container */
}

.search-results {
    position: absolute;
    top: 100%;
    /* Place it directly below the search input */
    left: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    list-style: none;
    margin: 0;
    /* Remove gap */
    padding: 0;
    width: 100%;
    /* Match the width of the search bar */
    z-index: 1000;
    /* Ensure it stays above other elements */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Optional shadow for better visibility */
}

.search-results li {
    padding: 10px;
    cursor: pointer;
}

.search-results li:hover {
    background-color: #f0f0f0;
    /* Highlight on hover */
}
@media (max-width: 480px){
    .header{
        padding: 10px 20px;
    }
    .logo img{
        width: 50px;
        height: 50px;
    }
}