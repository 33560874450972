.footer {
    background-color: #1a1a1a;
    color: white;
    padding: 60px 20px;
    max-width: 100%;
    margin: 0 auto;
}

/* Flexbox for content alignment */
.footer-content {
    display: flex;
    justify-content: space-between; /* Equal spacing between sections */
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;
}

 .footer-left, 
.footer-center, 
.footer-right {
    /* flex: 1; Ensure all sections take equal width */
    min-width: 200px; 
}
.footer-left {
    text-align: left;
    position: relative;
    overflow: hidden;
    width: 250px;
    height: 246px;
        border-radius: 10%;
}
.footer-left::before{
    content: '';
    position: absolute;
    width: 55%;
    z-index: 0;
    left: 13%;
    top: -32%;
    height: 394px;
    background-color: #bf9b42;
    box-shadow: 0 0 30px rgba(0, 0, 0);
    animation: rotate 3s linear infinite;
}
.footer-left::after{
    content: '';
    position: absolute;
    inset: 7px;
    background-color: #fff;
    border-radius: 50%;
    z-index: -1;
}
@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(359deg);
    }
}
.footer-logo {
    width: 250px;
    height: 250px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 14%;
    position: absolute;
}

.footer-center {
    text-align: center;
}

.footer-center h4 {
    font-size: 18px;
    font-family: 'Poppins';
    margin-bottom: 10px;
    font-weight: 700;
}

.footer-center ul {
    list-style: none;
    padding: 0;
}

.footer-center ul li {
    margin: 5px 0;
}

.footer-center ul li span {
    color: #bf9b42;
    text-decoration: none;
    font-size: 17px;
    position: relative;
    font-weight: 300;
    cursor: pointer;
}

.footer-center ul li span::after,
.footer-center ul li span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: white;
    left: 0;
    bottom: -5px;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
}

.footer-center ul li span:hover::after,
.footer-center ul li span:hover::before {
    visibility: visible;
    transform: scaleX(1);
    color: #0c1e2b;
}
.footer-center ul li span:hover{
  color: #fff;
} 
.footer-right p a:hover{
  color: #fff;
}
.footer-right {
    text-align: left;
}
.logoname{
  margin-left: 35px;
}
.footer-right h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 800;
}

.footer-right p {
    color: #bf9b42;
    text-decoration: none;
    font-size: 17px;
    position: relative;
    font-weight: 300;
    cursor: pointer;
}

.footer-right p a {
    color: #bf9b42;
    text-decoration: none;
    font-size: 17px;
    position: relative;
    font-weight: 300;
    cursor: pointer;
    text-decoration: none;
}

.footer-right p a:hover {
    text-decoration: underline;
}

hr {
    border: 0;
    height: 1px;
    background: #333;
    margin: 20px 0;
}

.footer-bottom {
    text-align: center;
    margin-top: 20px;
}

.icon-container {
    margin-top: 55px;
    margin-left: -10px;
    margin-bottom: 10px;
}

.icon {
    margin: 0 10px;
    color: white;
    font-size: 40px;
    text-decoration: none;
}

.whatsapp-icon:hover {
    color: #25D366;
}

.instagram-icon:hover {
    color: #E4405F;
}

.facebook-icon:hover {
    color: #4267B2;
}

.linkedin-icon:hover {
    color: red;
}

.footer-bottom p {
    font-size: 14px;
    color: #fff;
}


.easter-animation {
    position: relative;
    margin-top: 116px;
  }
  
/* Mobile and Tablet Styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
    }
    .logoname{
      margin-left: 0px;
    }
    .footer-left, 
    .footer-center, 
    .footer-right {
        text-align: center;
        flex: none;
        width: 100%;
        margin-bottom: 20px;
    }

    .footer-center ul li span {
        font-size: 16px;
    }

    .icon {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .footer {
        padding: 20px 10px;
    }
.footer-left{
    width: 34%;
    height: 152px;
}
    .footer-logo {
        width: 192px;
        height: 162px;
        left: 3%;
        top: -4%;
    }
    .footer-left::before{
        content: '';
        position: absolute;
        width: 39%;
        z-index: 0;
        left: 30%;
        top: -29%;
        height: 252px;
        box-shadow: 0 0 30px rgba(0, 0, 0);
        animation: rotate 3s linear infinite;
    }

    .footer-left p, 
    .footer-center ul li span, 
    .footer-right p {
        font-size: 15px;
    }

    .icon {
        font-size: 22px;
    }
}
