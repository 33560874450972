.acrylic-product-gallery {
    text-align: center;


  }
  .acrylic-product-gallery h1{
    color: #063931;
  }
  
  .acrylic-product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .acrylic-product-card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 204px;
    height: 285px;
    transition: transform 0.2s;
  }
  .acrylic-product-card .add-to-cart-btn{
    margin-left: 20px;
  }
  .acrylic-products-image{
    width: 136px;
    height: 124px;
  }
  .arc{
    margin-left: 14% !important;
  }
  .add-to-cart-btn {
    background-color: #4CAF50;
    color: white;
    padding: 10px 45px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-top: 15px;
  }
  
  .add-to-cart-btn:hover {
    background-color: #45a049;
  }
  
  .add-to-cart-btn svg {
    margin-right: 5px;
  }
  /* In your CSS or styled components */
.product-page-container {
    margin-bottom: 40px; /* Add some space between sections */
  }
  
  .acrylic-product-page-container {
    margin-top: 40px; /* Ensure some spacing above Acrylic products */
  }
  
