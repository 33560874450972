/* Styles for the 404 Page */
.notfound-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* Light background color */
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  
  .notfound-content {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    margin: auto;
  }
  
  .notfound-icon {
    font-size: 80px;
    color: #f44336; /* Red for attention */
  }
  
  .notfound-title {
    font-size: 2rem;
    margin: 20px 0;
    color: #333;
  }
  
  .notfound-message {
    font-size: 1rem;
    color: #777;
    margin-bottom: 30px;
  }
  
  .back-home-button {
    display: inline-flex;
    align-items: center;
    background-color: #4caf50;
    color: white;
    font-size: 1.2rem;
    padding: 12px 25px;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .back-home-button:hover {
    background-color: #388e3c;
  }
  
  .home-icon {
    margin-right: 8px;
    font-size: 1.6rem;
  }
  