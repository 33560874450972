/* General Cart Table Styling */
.cart-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.cart-table th, .cart-table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.cart-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.cart-table td img {
  width: 60px; /* Reduced image size */
  height: auto;
  object-fit: cover;
}

.cart-table .quantity-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-table .quantity-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  margin: 0 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.cart-table .quantity-controls button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.cart-table .delete-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.cart-table .delete-btn:hover {
  background-color: #c82333;
}

/* Grand Total and Place Order Section */
.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
}

.cart-summary .grand-total {
  color: #007bff;
}

.cart-summary .place-order-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.cart-summary .place-order-btn:hover {
  background-color: #218838;
}
.empty-cart-image {
  margin-top: 20px;
  max-width: 300px; /* Adjust size as needed */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .cart-table th, .cart-table td {
    padding: 8px;
  }

  .cart-table td img {
    width: 40px; /* Smaller image size on mobile */
  }

  .cart-summary {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-summary .grand-total {
    margin-bottom: 10px;
  }

  .cart-summary .place-order-btn {
    width: 100%; /* Full width button on mobile */
    text-align: center;
  }
}

/* Mobile First Small Devices */
@media (max-width: 480px) {
  .cart-table, .cart-table th, .cart-table td {
    font-size: 14px;
  }

  .cart-table td img {
    width: 35px;
  }

  .cart-summary {
    font-size: 16px;
  }
}

