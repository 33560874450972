@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');

/*  */
/* Specific styles for the login/signup container */
/* Wrapper for centering */
.login-signup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #1a1a1a; /* Optional background color for full-page effect */
}

/* Specific styles for the login/signup container */
.login-signup-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: 750px;
    height: 550px;
    border: 2px solid #ff2770;
    box-shadow: 0 0 25px #ff2770;
    overflow: hidden;
    background: #25252b; /* Specific background for the login/signup form */
}
/* All other styles remain unchanged... */
.login-signup-container .form-box {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
#reg{
    color:white
}
.login-signup-container {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    position: relative;
    width: 750px;
    height: 600px;
    border: 2px solid #ff2770;
    box-shadow: 0 0 25px #ff2770;
    overflow: hidden;
    background: #25252b; /* Specific background for the login/signup form */
}

.login-signup-container .form-box {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.form-box.Login {
    left: 0;
    padding: 0 40px;
}

.form-box.Login .animation {
    transform: translateX(0%);
    transition: .7s;
    opacity: 1;
    transition-delay: calc(.1s * var(--S));
}

.login-signup-container.active .form-box.Login .animation {
    transform: translateX(-120%);
    opacity: 0;
    transition-delay: calc(.1s * var(--D));
}

.form-box.Register {
    right: 0;
    padding: 0 60px;
}

.form-box.Register .animation {
    transform: translateX(120%);
    transition: .7s ease;
    opacity: 0;
    filter: blur(10px);
    transition-delay: calc(.1s * var(--S));
}

.login-signup-container.active .form-box.Register .animation {
    transform: translateX(0%);
    opacity: 1;
    filter: blur(0px);
    transition-delay: calc(.1s * var(--li));
}

.form-box h2 {
    font-size: 32px;
    text-align: center;
}

.form-box .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin-top: 25px;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    border-bottom: 2px solid #fff;
    padding-right: 23px;
    transition: .5s;
}

.input-box input:focus,
.input-box input:valid {
    border-bottom: 2px solid #ff2770;
}

.input-box label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 16px;
    color: #fff;
    transition: .5s;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
    top: -5px;
    color: #ff2770;
}

.input-box box-icon {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 18px;
    transform: translateY(-50%);
    color: #fff;
}

.input-box input:focus ~ box-icon,
.input-box input:valid ~ box-icon {
    color: #ff2770;
}
.password-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    font-size: 18px; /* Adjust size */
    color: #aaa; /* Icon color */
}
.btn {
    position: relative;
    width: 100%;
    height: 45px;
    background: transparent;
    border-radius: 40px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    border: 2px solid #ff2770;
    overflow: hidden;
    z-index: 1;
}

.btn::before {
    content: "";
    position: absolute;
    height: 300%;
    width: 100%;
    background: linear-gradient(#25252b, #ff2770, #25252b, #ff2770);
    top: -100%;
    left: 0;
    z-index: -1;
    transition: .5s;
}

.btn:hover:before {
    top: 0;
}
.link-button {
    background: none;
    border: none;
    color: #ff2770; /* Match your theme */
    font-size: 14px; /* Adjust as needed */
    cursor: pointer;
    text-decoration: underline; /* Optional for a link effect */
}

.link-button:hover {
    color: #ff1a5f; /* Optional hover color */
}

.regi-link {
    font-size: 14px;
    text-align: center;
    margin: 20px 0 10px;
}

.regi-link a {
    text-decoration: none;
    color: #ff2770;
    font-weight: 600;
}

.regi-link a:hover {
    text-decoration: underline;
}

.info-content {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.info-content.Login {
    right: 0;
    text-align: right;
    padding: 0 40px 60px 150px;
}

.info-content.Login .animation {
    transform: translateX(0);
    transition: .7s ease;
    transition-delay: calc(.1s * var(--S));
    opacity: 1;
    filter: blur(0px);
}

.login-signup-container.active .info-content.Login .animation {
    transform: translateX(120%);
    opacity: 0;
    filter: blur(10px);
    transition-delay: calc(.1s * var(--D));
}

.info-content.Register {
    left: 0;
    text-align: left;
    padding: 0 150px 60px 38px;
    pointer-events: none;
}

.info-content.Register .animation {
    transform: translateX(-120%);
    transition: .7s ease;
    opacity: 0;
    filter: blur(10PX);
    transition-delay: calc(.1s * var(--S));
}

.login-signup-container.active .info-content.Register .animation {
    transform: translateX(0%);
    opacity: 1;
    filter: blur(0);
    transition-delay: calc(.1s * var(--li));
}
.to{
    color: #fff;
}
.info-content h2 {
    text-transform: uppercase;
    font-size: 36px;
    line-height: 1.3;
}

.info-content p {
    font-size: 16px;
}

.login-signup-container .curved-shape {
    position: absolute;
    right: 0;
    top: -5px;
    height: 600px;
    width: 850px;
    background: linear-gradient(45deg, #25252b, #ff2770);
    transform: rotate(10deg) skewY(40deg);
    transform-origin: bottom right;
    transition: 1.5s ease;
    transition-delay: 1.6s;
}

.login-signup-container.active .curved-shape {
    transform: rotate(0deg) skewY(0deg);
    transition-delay: .5s;
}

.login-signup-container .curved-shape2 {
    position: absolute;
    left: 250px;
    top: 100%;
    height: 700px;
    width: 850px;
    background: #25252b;
    border-top: 3px solid #ff2770;
    transform: rotate(0deg) skewY(0deg);
    transform-origin: bottom left;
    transition: 1.5s ease;
    transition-delay: .5s;
}

.login-signup-container.active .curved-shape2 {
    transform: rotate(-11deg) skewY(-41deg);
    transition-delay: 1.2s;
}
/* Existing CSS remains unchanged */

/* Add media query for mobile view */
@media (max-width: 768px) {
    /* Adjust the size of the login-signup container */
    .login-signup-container {
        width: 100%; /* Make the container responsive */
        height: auto; /* Adjust height to content */
        padding: 20px; /* Add padding for mobile */
        box-shadow: none; /* Optional: reduce shadow for mobile */
        border: none; /* Optional: remove border for cleaner look on mobile */
    }

    /* Hide unnecessary elements for mobile view */
    .info-content,
    .curved-shape,
    .curved-shape2 {
        display: none; /* Hide decorative shapes and information content */
    }

    /* Keep only the form boxes visible */
    .form-box.Login,
    .form-box.Register {
        width: 100%; /* Take full width on mobile */
        padding: 0 20px; /* Add padding to the forms */
        position: static; /* Remove absolute positioning for mobile */
        box-shadow: none; /* Remove box shadow from forms */
    }

    /* Specifically for Register form, position it at the top */
    .form-box.Register {
        top: 0; /* Ensure the Register form is positioned at the top */
    }

    /* Adjust button size for better touch support */
    .btn {
        height: 50px; /* Slightly larger for mobile touch screens */
    }
}