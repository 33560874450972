.acrylic-page {
  padding: 60px 20px;
  text-align: center;
  background-color: #063931;
}
.acrylic-page h1{
  color: #bf9b42;
}
.acrylic-card h3{
  color: #bf9b42;
}
.acrylic-product-grid {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap;
}
.acrylic-product-images{
  width: 175px;
  height: 175px;
}
.acrylic-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 15px;
  text-align: center;
  transition: transform 0.2s;
}

.acrylic-card:hover {
  transform: scale(1.05);
}

/* .acrylic-product-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
} */

.acrylic-add-to-cart-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.acrylic-add-to-cart-btn:hover {
  background-color: #218838;
}

/* Mobile view */
@media (max-width: 768px) {
  .acrylic-product-grid {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    justify-content: flex-start;
    padding-bottom: 15px;
  }

  .acrylic-card {
    flex: 0 0 auto;
    width: 200px; /* Adjust width for smaller screens */
    margin: 0 10px; /* Add space between cards */
  }
}
@media (max-width:480px){
  h1{
    font-size: 22px;
    font-weight: 500;
  }
  .acrylic-page{
    padding: 20px 20px;
  }

}
